<template>
    <span @click.prevent.stop="showModal" class="Modal-trigger" :style="style">
        <slot>
        </slot>
    </span>
    <Teleport to="body">
        <div v-if="isVisible" class="Modal-body d-flex align-items-center" :class="props.class"
            @click.self.prevent.stop="hideModal()">
            <div class="container">
                <div class="row justify-content-center">
                    <slot name="modal" />
                </div>
            </div>
        </div>
    </Teleport>

</template>

<script setup lang="ts">
const $emit = defineEmits(['open'])
const props = defineProps({
    visible: {
        type: Boolean,
        default: false
    },
    style: {
        type: Object,
        default: {}
    },
    class: {
        type: [Array, Object, String],
        default: {}
    },
    onclose: {
        type: Function,
        default: () => { }
    }
})



const isVisible = ref(props.visible)


watch(props, (newProps) => {
    isVisible.value = newProps.visible
})

function showModal() {
    if (isVisible.value == true) return
    isVisible.value = true
    $emit('open', true)
}

function hideModal() {
    if (isVisible.value == false) return
    isVisible.value = false
    if (props.onclose) props.onclose();
}

defineExpose({
    showModal,
    hideModal
})

function keyDown(e: KeyboardEvent) {
    if (e.key == "Escape") {
        hideModal()
    }
}
onMounted(() => {
    window.addEventListener("keydown", keyDown);
});

onUnmounted(() => {
    window.removeEventListener("keydown", keyDown);
});

</script>

<style lang="scss">
.Modal-trigger {
    cursor: pointer;
}

.Modal-body {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2);
    z-index: 2023;

    .container {
        border: 1px solid #000;
        background-color: #fff;
    }
}
</style>
